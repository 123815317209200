import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '@core/services/business/auth/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PRIMARY_COLOR } from '@shared/constants/constants.const';

interface ISidebarMenuItem {
  color: string;
  iconKey: string;
  isParticipantMenu: boolean;
  label: string;
  route: string;
}

const DEFAULT_MENU_ICON_COLOR = '#797E8C';
const SELECTED_MENU_ICON_COLOR = PRIMARY_COLOR;

@UntilDestroy()
@Component({
  selector: 'kp-profile-sidebar-menu',
  templateUrl: './profile-sidebar-menu.component.html',
  styleUrls: ['./profile-sidebar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSidebarMenuComponent {
  @Input() isPageShort = false;
  @Input() pageUrl: string;
  @Input() isProfileAvatarLoaded: boolean;

  public readonly sidebarMenuItems: ISidebarMenuItem[] = [
    {
      color: DEFAULT_MENU_ICON_COLOR,
      iconKey: 'homeMenuIcon',
      isParticipantMenu: false,
      label: 'Лента',
      route: 'newsline',
    },
    {
      color: DEFAULT_MENU_ICON_COLOR,
      iconKey: 'calendarMenuIcon',
      isParticipantMenu: false,
      label: 'Мероприятия',
      route: 'events',
    },
    {
      color: DEFAULT_MENU_ICON_COLOR,
      iconKey: 'messageMenuIcon',
      isParticipantMenu: true,
      label: 'Чаты',
      route: 'messages',
    },
    {
      color: DEFAULT_MENU_ICON_COLOR,
      isParticipantMenu: true,
      iconKey: 'usersMenuIcon',
      label: 'Участники',
      route: 'members',
    },
    {
      color: DEFAULT_MENU_ICON_COLOR,
      isParticipantMenu: false,
      iconKey: 'menuEdit',
      label: 'Бронирование',
      route: 'booking',
    },
    {
      color: DEFAULT_MENU_ICON_COLOR,
      isParticipantMenu: false,
      iconKey: 'setting2Icon',
      label: 'Управление',
      route: 'admin',
    },
  ];
  public readonly hasPreviousPage: boolean = window.history.length > 1;

  constructor(private authService: AuthService) {}

  public goToPreviousPage(): void {
    window.history.back();
  }

  public changeColorForMenuItemOnFocus(item: ISidebarMenuItem): string {
    return item.route === this.pageUrl ? SELECTED_MENU_ICON_COLOR : DEFAULT_MENU_ICON_COLOR;
  }

  get isUserStaff(): boolean {
    return this.authService.currentUser?.isStaff;
  }

  get isCurrentPageProfile(): boolean {
    return this.pageUrl?.includes('profile');
  }
}
